import React, { useState } from "react"
import { Link } from "gatsby"
import "./Header.scss"
import Logo from "../../../assets/images/logo.png"
import useMobile from "../../../hooks/useMobile"

const Header = () => {

  const isMobile = useMobile()

  const [menuActice, setMenuActice] = useState(false)

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__logo">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>

        {isMobile ? (
          <div className="header__menu-mob">
            <div
              className={`header__burger-menu ${menuActice ? "active" : ""}`}
              onClick={() => setMenuActice(!menuActice)}
            >
              <span />
            </div>

            <div className={`header__menu-mob-content ${menuActice ? "active" : ""}`}>
              <nav>
                <Link to="/" activeStyle={{ color: "#94573A" }}>Inicio</Link>
                <Link to="/habitaciones" activeStyle={{ color: "#94573A" }}>Habitaciones</Link>
                <Link to="/servicios" activeStyle={{ color: "#94573A" }}>Servicios</Link>
                <Link to="/galeria" activeStyle={{ color: "#94573A" }}>Galeria</Link>
                <Link to="/contactanos" activeStyle={{ color: "#94573A" }}>Contáctanos</Link>
              </nav>

            </div>
          </div>

        ) : (
          <div className="header__menu-desk">
            <nav>
              <Link to="/" activeStyle={{ color: "#94573A" }}>Inicio</Link>
              <Link to="/habitaciones" activeStyle={{ color: "#94573A" }}>Habitaciones</Link>
              <Link to="/servicios" activeStyle={{ color: "#94573A" }}>Servicios</Link>
              <Link to="/galeria" activeStyle={{ color: "#94573A" }}>Galeria</Link>
              <Link to="/contactanos" activeStyle={{ color: "#94573A" }}>Contáctanos</Link>
            </nav>

          </div>
        )}


      </div>
    </header>
  )
}
export default Header
