import React from "react"
import "./Footer.scss"

import logo from "../../../assets/images/logo_footer.png"
import { graphql, Link, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"


const Footer = () => {
  const { allContentfulFooter } = useStaticQuery(
    graphql`
        query Footer_Query {
            allContentfulFooter {
                nodes {
                    logo {
                        fluid {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                        }
                        description
                    }
                    informacion {
                        raw
                    }
                    redesSociales {
                        icono
                        redireccion
                        titulo
                    }
                }
            }
        }

    `
  )

  const contentInfo = allContentfulFooter.nodes[0]
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__info">

          <div className="footer__sitemap">
            <h3>Menu</h3>
            <nav>
              <Link to="/">Inicio</Link>
              <Link to="/habitaciones">Habitaciones</Link>
              <Link to="/servicios">Servicios</Link>
              <Link to="/galeria">Galeria</Link>
              <Link to="/contactanos">Contáctanos</Link>
            </nav>
          </div>
          <div className="footer__direction">
            {documentToReactComponents(JSON.parse(contentInfo.informacion.raw))}
          </div>
          <div className="footer__logo">
            <Img
              className="img-fluid"
              loading="lazy"
              itemProp="image"
              fluid={contentInfo.logo.fluid}
              alt={contentInfo.logo.description}
              draggable={false}
            />
          </div>
        </div>

        <div className="footer__social">
          <ul>
            {contentInfo.redesSociales.map((item, index) => {
              return (
                <li key={`social-${index}`}><a href={item.redireccion} target="_blank"><i className={item.icono}></i></a></li>

              )
            })}
          </ul>

        </div>
        <div className="footer__copy">
          <p>Copyright © {new Date().getFullYear()} | Todos los derechos Reservados</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
